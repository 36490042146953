import { FilloutFullScreenEmbed } from "@fillout/react";

import "@fillout/react/style.css";

import { useAuthStore } from "@/stores/authStore.tsx";

function FilloutAmfFormPage() {
	const partnershipId = useAuthStore((state) => state.partnershipId);
	//TODO: replace placeholder_partnershipId with the actual partnershipId
	return (
		<FilloutFullScreenEmbed
			parameters={{
				partnershipId: partnershipId || "placeholder_partnershipId",
			}}
			filloutId={import.meta.env.VITE_PUBLIC_FILLOUT_FORM_ID}
			inheritParameters
		/>
	);
}

export default FilloutAmfFormPage;

import type { ReactNode } from "react";

interface ProtectedRouteProps {
	children: ReactNode;
}

export const PrivateRoute = ({ children }: ProtectedRouteProps) => {
	// useAuthQueryParamsSetter();
	// const loading = useAuthCheck();
	//
	// if (loading) {
	// 	return		<CustomSpinner />;
	// }

	return <>{children}</>;
};
